@media only screen and (max-width: 768px) {
	.mat-step-label {
		display: none !important;
	}

	.mat-stepper-horizontal {
		.mat-horizontal-stepper-header-container {
			padding: 10px;
			border: none;

			.mat-step-header {
				min-width: 29px !important;
				max-height: 30px !important;
				margin: 0 0 0 0 !important;
				padding: 0 0 0 0 !important;
				background-color: var(--color_default_white) !important;

				.mat-step-icon {
					padding: 0;
					margin: 0;
				}

				.mat-step-icon-selected {
					margin-right: 2px;
					padding: 0;
					border: solid 2px var(--color_primary) !important;
				}

				.mat-stepper-horizontal-line {
					margin: 0;
					padding: 0;
				}
			}
		}
	}
}
