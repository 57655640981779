@import 'node_modules/@bs2/utilities-v9/assets/css/themes/v2/base/_bs2-theme.scss';



// structure
@import './base/structure';
@import './base/mixins';

// components
@import './components/paginator';
@import './components/stepper';
@import './components/header-aprovacoes';
@import './components/filter-comprovantes';
@import './components/filter-extrato';
@import './components/footer-mobile';
@import './components/mat-select';
@import './components/commons';
@import './components/cartoes-colors';

//guide
@import './guide/colors-pay';

// merge com utilities
@import './components/mat-accordion';
@import './components/mat-button';
@import './components/mat-option';

// custom
@import './components/custom-icons';

.mat-stepper-horizontal {
    background-color: transparent;
}