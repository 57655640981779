.mat-mdc-tooltip {
	font-size: 12px !important;
	padding-bottom: 4px;
	opacity: 1;
	line-height: 2;
	max-width: 750px !important;
	width: auto;
	height: 27px;
}

@media only screen and (max-width: 768px) {
	.pg {
		margin-top: -33px;
	}

	.bs2-card {
		padding: 0;
	}

	.opened {
		z-index: 8888 !important;
	}

	.bx-conta {
		z-index: 9999 !important;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
	.mat-radio-container {
		background-color: transparent !important;
	}
	.mdc-radio__outer-circle {
		border: solid 2px var(--color_primary) !important;
	}

	.mdc-switch__ripple {
		background-color: transparent !important;
	}
	.mat-mdc-tab-header-pagination {
		display: none !important;
	}
}
