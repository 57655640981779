@media only screen and (max-width: 800px) {
	.pg-authenticated-extrato-lista {
		.wr-extrato-lista {
			.bs2-card {
				.bx-active-filters {
					position: relative;

					.bt-filter-button {
						position: fixed;
						bottom: 70px;
						right: 20px;
						background-color: var(--color_primary);
						.mdc-button__label {
							i {
								color: var(--color_default_white);
							}
						}
					}

					.bx-filters {
						margin: 0;

						.bx-ng-content {
							display: block;
						}

						.filter-range {
							display: block;
						}
						.mat-mdc-chip-list {
							position: absolute;
							bottom: 0;
							left: 10px;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 400px) and (min-width: 360px) {
	.pg-authenticated-extrato-lista {
		.wr-extrato-lista {
			.bs2-card {
				.bx-active-filters {
					position: relative;
					padding: 13px;

					.bt-filter-button {
						position: fixed;
						bottom: 70px;
						right: 20px;
						background-color: var(--color_primary);
						.mdc-button__label {
							i {
								color: var(--color_default_white);
							}
						}
					}

					.bx-filters {
						margin: 0;

						.bx-ng-content {
							display: block;
						}

						.filter-range {
							display: block;
						}
						.mat-mdc-chip-list {
							position: absolute;
							bottom: 0;
							left: 5px;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 350px) and (min-width: 310px) {
	.pg-authenticated-extrato-lista {
		.wr-extrato-lista {
			.bs2-card {
				.bx-active-filters {
					position: relative;
					margin: 0;
					padding: 10px;

					.bt-filter-button {
						position: fixed;
						bottom: 70px;
						right: 20px;
						background-color: var(--color_primary);
						.mdc-button__label {
							i {
								color: var(--color_default_white);
							}
						}
					}

					.bx-filters {
						margin: 0;

						.bx-ng-content {
							display: block;
						}

						.filter-range {
							max-width: 60%;
							display: block;
						}
						.mat-mdc-chip-list {
							position: absolute;
							bottom: 0;
							left: 5px;
						}
					}
				}
			}
		}
	}
}
