.mat-mdc-button.mdc-button {
	&.ghost {
		background-color: var(--color_default_white) !important;
		border: none;
		box-shadow: 0 2px 12px -4px rgba(121, 124, 218, 0.4);
		.mdc-button__label {
			color: var(--color_primary);
		}

		&:hover {
			background-color: var(--color_primary) !important;
			color: var(--color_default_white);
			.mdc-button__label {
				color: var(--color_default_white);
			}
		}

		&:disabled,
		&.disabled {
			background-color: var(--color_default_white) !important;
			border: 1px solid var(--color_gray_2);
			.mdc-button__label {
				color: var(--color_gray_2);
			}
		}
	}
}
