@media only screen and (max-width: 768px) {
	.pg-authenticated-aprovacoes-lista {
		.mat-mdc-tab-group {
			.mat-mdc-tab-body-wrapper {
				.mat-mdc-tab-body {
					.mat-mdc-tab-body-content {
						.wr-aprovacoes-lista {
							.bs2-card {
								.bx-aprovacoes-header {
									width: calc(100% - 175px);
									padding-bottom: 40px;
									position: fixed;
									bottom: 50px;
									left: 95px;
									z-index: 999;
									padding-top: 20px;

									.bx-itens {
										min-width: 100%;
										text-align: center;

										h2 {
											min-width: 100%;
											font-size: 14px;
											margin: 5px 0 20px 0;
										}
									}

									.bx-itens-buttons {
										display: flex;
										justify-content: center;

										button:last-child {
											margin-right: 0;
										}
									}
								}

								.mat-mdc-table {
									.mat-mdc-row {
										.mat-mdc-cell.cell-checkbox-item {
											border: none;

											.checkbox-item {
												position: absolute;
												top: 35px;
												right: 35px;
												z-index: 888;
											}
										}
									}
								}
								.mdc-evolution-chip-set__chips {
									.table-chip {
										margin-left: 0 !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
