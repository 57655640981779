.mat-mdc-option {
	&.multline {
		height: unset;
		.mdc-list-item__primary-text.mdc-list-item__primary-text {
			white-space: normal;
			line-height: 16px;
			font-size: 12px;
		}

		// &:nth-of-type(odd) {
		//     background-color: var(--color_gray_1);
		// }
	}
}
