@media only screen and (max-width: 768px) {
	.wr-footer {
		min-width: 100% !important;
		height: 60px !important;
		position: fixed !important;
		bottom: 50px !important;
		left: 0 !important;
		background-color: var(--color_default_white);
		z-index: 0;
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);

		.bx-footer {
			width: calc(100% - 65px) !important;
			display: flex !important;
			justify-content: flex-end !important;
			margin: 0 20px 0 65px !important;
		}
	}
}
