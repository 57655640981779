.mat-accordion {
	.mat-expansion-panel {
		margin-bottom: 8px;
		box-shadow: none;

		.mat-expansion-panel-header {
			background-color: var(--color_gray_1) !important;

			&:hover {
				background-color: var(--color_gray_1) !important;
			}
		}

		.mat-expansion-panel-content {
			.mat-expansion-panel-body {
				padding: 0 6px 16px;
			}
		}

		&.mat-expansion-panel-spacing {
			margin: 0;
		}
	}
}
