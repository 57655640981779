@media only screen and (max-width: 768px) {
	.pg-authenticated-comprovantes-lista {
		.wr-comprovantes-lista {
			.bs2-card {
				.bx-active-filters {
					position: relative;

					.bt-filter-button {
						position: fixed;
						bottom: 70px;
						right: 20px;
						background-color: var(--color_primary) !important;
						.mdc-button__label {
							i {
								color: var(--color_default_white) !important;
							}
						}
					}

					.bx-filters {
						margin: 0;

						.bx-ng-content {
							display: block;
						}

						.filter-range {
							display: block;
						}
						.mat-mdc-chip-list {
							position: absolute;
							bottom: 0;
							left: 5px;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 380px) {
	.pg-authenticated-comprovantes-lista {
		.wr-comprovantes-lista {
			.bs2-card {
				.bx-active-filters {
					position: relative;
					padding: 13px;

					.bt-filter-button {
						position: fixed;
						bottom: 70px;
						right: 20px;
						background-color: var(--color_primary) !important;
						.mdc-button__label {
							i {
								color: var(--color_default_white) !important;
							}
						}
					}

					.bx-filters {
						margin: 0;

						.bx-ng-content {
							display: block;
						}

						.filter-range {
							display: block;
						}
						.mat-mdc-chip-list {
							position: absolute;
							bottom: 0;
							left: 5px;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 370px) {
	.pg-authenticated-comprovantes-lista {
		.wr-comprovantes-lista {
			.bs2-card {
				.bx-active-filters {
					position: relative;
					padding: 13px;

					.bt-filter-button {
						position: fixed;
						bottom: 70px;
						right: 20px;
						background-color: var(--color_primary) !important;
						.mdc-button__label {
							i {
								color: var(--color_default_white) !important;
							}
						}
					}

					.bx-filters {
						margin: 0;

						.bx-ng-content {
							display: block;
						}

						.filter-range {
							display: block;
						}
						.mat-mdc-chip-list {
							position: absolute;
							bottom: 0;
							left: 5px;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 320px) {
	.pg-authenticated-comprovantes-lista {
		.wr-comprovantes-lista {
			.bs2-card {
				.bx-active-filters {
					position: relative;
					margin: 0 0 0 0 !important;
					padding: 10px !important;

					.bt-filter-button {
						position: fixed;
						bottom: 70px;
						right: 20px;
						background-color: var(--color_primary) !important;
						.mdc-button__label {
							i {
								color: var(--color_default_white) !important;
							}
						}
					}

					.bx-filters {
						margin: 0;

						.bx-ng-content {
							min-width: 155px;
							max-width: 155px;
							display: block;
						}

						.filter-range {
							max-width: 60%;
							display: block;
						}
						.mat-mdc-chip-list {
							position: absolute;
							bottom: 0;
							left: 5px;
						}
					}
				}
			}
		}
	}
}
