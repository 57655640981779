.wr-site {
	.pg {
		.pg-title {
			display: flex;
			flex-direction: row;
			padding: 0 10px 32px 0;
			box-sizing: border-box;

			:first-child {
				flex: 1;
			}
		}
	}
}

@media (max-width: 450px) {
	.ct {
		max-width: 305px;
	}
}

* {
	-webkit-tap-highlight-color: transparent;
}

.mat-mdc-form-field {
	.mat-mdc-form-text-infix {
		width: auto;
	}
	.mat-mdc-select-arrow {
		color: transparent !important;
		position: relative;
		margin: 0;
		width: 18px;

		&::before {
			content: '\e149';
			color: var(--color_primary);
			font-family: 'bs2-font-icon-v1';
			font-size: 24px;
			font-weight: normal;
			left: 50%;
			line-height: 0;
			transform: translate(-50%, 0);
		}
	}

	&.small {
		.mat-mdc-form-text-infix {
			border-top: 0.3em solid transparent;
		}
		.mat-mdc-select-arrow {
			margin-top: 4px;
		}
	}
}

.bx-not-found {
	background-color: var(--color_default_white);
	width: 100%;
	position: relative;
	padding: 20px 0;
	color: var(--color_gray_3);
	font-size: 12px;
	text-align: center;

	i {
		font-size: 52px;
		color: var(--color_gray_2);
	}

	h4 {
		font-size: 14px;
		color: var(--color_default_black);
		font-weight: 600;
		margin: 0 0 10px 0;
	}

	span {
		display: block;
		margin: 15px 0 0 0;
		color: #5050d2;
		cursor: pointer;
	}
}

.bx-error {
	background-color: var(--color_default_white);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;

	.bx-error-container {
		width: calc(100% - 100px);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: var(--color_gray_3);
		font-size: 12px;
		text-align: center;

		h4 {
			font-size: 14px;
			color: var(--color_default_black);
			font-weight: 600;
			margin: 0 0 10px 0;
		}

		span {
			display: block;
			margin: 15px 0 0 0;
			color: #5050d2;
			cursor: pointer;
		}
	}
}

/* Material overrides */

.mat-primary {
	color: var(--color_primary) !important;
}

.mat-icon {
	font-family: 'Material Icons' !important;
}

.mat-icon.mat-primary {
	color: var(--color_primary);
}

.mat-form-field-appearance-outline.mat-focused .mdc-notched-outline {
	color: var(--color_primary);
}

.mat-mdc-form-field.mat-focused .mat-mdc-floating-label:not(.mat-form-field-invalid) {
	color: var(--color_primary);
}

.mat-mdc-checkbox-checked.mat-primary .mdc-checkbox__background,
.mat-checkbox-indeterminate.mat-primary .mdc-checkbox__background {
	background-color: var(--color_primary);
}

.mat-mdc-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mdc-switch__ripple {
	background-color: var(--color_primary);
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-search-clear {
	width: 20px !important;
	height: 20px !important;
	top: 10px !important;
	right: 10px !important;

	span {
		.mat-icon {
			font-size: 14px !important;
			line-height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-search-panel {
	overflow-y: scroll !important;

	/* width */
	&::-webkit-scrollbar {
		width: 8px;
		border-radius: 8px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 10px;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: var(--color_primary_light);
		border-radius: 10px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: var(--color_primary);
		border-radius: 10px;
	}
}

.cdk-overlay-container {
	z-index: 10000;
}

.mat-mdc-optgroup {
	&.bloqueado {
		label {
			&:after {
				content: ' bloqueado';
				font-size: 10px;
				color: var(--color_system_error);
			}
		}
	}
}

.mat-mdc-tooltip {
	font-size: 12px;
	margin: 14px !important;
}

.mat-badge-accent .mat-badge-content {
	background: var(--color_system_error);
	color: var(--color_default_white);
	font-weight: 500;
}

// Levar para utilities futuramente

.mat-mdc-slide-toggle .mdc-switch__handle {
	background-color: var(--color_default_white);
	border: solid 1px var(--color_primary);
}

.mat-mdc-slide-toggle .mdc-switch {
	background-color: var(--color_primary);
	border: solid 1px var(--color_primary);
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked .mdc-switch__handle {
	background-color: var(--color_primary);
	border: solid 1px var(--color_gray_2);
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked .mdc-switch {
	background-color: var(--color_gray_2);
}

.mat-mdc-radio-button.mat-primary.mat-mdc-radio-checked .mdc-radio__outer-circle {
	border-color: var(--color_primary);
}

.mat-mdc-radio-button.mat-primary .mdc-radio__inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-mdc-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
	background-color: var(--color_primary);
}
 

.table-chip {
	margin: auto !important;
	background-color: var(--color_primary);
	color: var(--color_default_black) !important;
	font-size: 9px !important;
	padding: 0 6px !important;
	text-align: center !important;
	font-weight: 500 !important;
	justify-content: center !important;

	&.mat-mdc-standard-chip {
		--mdc-chip-container-height: 14px;
		--mdc-chip-label-text-size: 9px;

		.mdc-evolution-chip__action {
			padding-left: 4px;
    	padding-right: 4px;
		}
	}
}

.bx-saldo {
	margin: 20px 0 40px 0;
	position: relative;

	@media (min-width: 768px) {
		margin: 0 0 40px 0;
		float: right;
		width: 300px;
	}

	.bx-title {
		padding-bottom: 10px !important;
	}

	.bx-saldo-atual {
		position: relative;
		display: flex;
		align-content: center;

		.bx-saldo-valor {
			min-width: calc(100% - 50px);

			@media (min-width: 769px) {
				min-width: 220px;
			}

			.h1 {
				font-size: 22px;
				white-space: nowrap;

				@media (min-width: 769px) {
					font-size: 32px;
				}
			}
		}

		.icon {
			color: var(--color_primary);
			font-size: 30px;
			margin-left: 20px;
			display: inline-flex;
			width: auto;
			align-items: center;
			cursor: pointer;
		}
	}

	.bx-saldo-bloqueado {
		padding: 8px 0;
	}

	.bx-saldo-limite {
		padding: 8px 0;
	}
}
